<template>
  <div class="login-container">
    <div class="content">
      <img src="/images/bg.png" class="img-bg" />
      <!-- <div class="title-wrapper">
        <img src="/images/title.png" alt srcset />
      </div>
      <div class="yun yun1">
        <img src="/images/yun1.svg" alt />
      </div>
      <div class="yun yun2">
        <img src="/images/yun2.svg" alt />
      </div>
      <div class="yun yun3">
        <img src="/images/yun3.svg" alt />
      </div>-->
      <div class="login">
        <div class="login_title title">
          <!-- <img src="/images/logo.png" alt /> -->
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          label-width="0px"
          class="demo-ruleForm login-page demo-form-inline"
        >
          <el-form-item prop="userName">
            <el-input
              type="text"
              v-model.trim="ruleForm.userName"
              auto-complete="off"
              placeholder="用户名"
            >
              <i slot="prefix" class="el-input__icon">
                <img :src="ruleForm.userName?'/images/user1.png':'/images/user.png'" />
              </i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model.trim="ruleForm.password"
              auto-complete="off"
              placeholder="密码"
            >
              <i slot="prefix" class="el-input__icon">
                <img :src="ruleForm.password?'/images/psw1.png':'/images/psw.png'" />
              </i>
            </el-input>
          </el-form-item>
          <!--  <el-form-item>
              <el-checkbox v-model="checked" class="rememberme">记住密码</el-checkbox>
          </el-form-item>-->
          <el-form-item>
            <el-button
              type="primary"
              style="width:100%"
              size="large"
              sort="loginBtn"
              @click="handleSubmit"
              :loading="logining"
            >登录</el-button>
          </el-form-item>
          <el-form-item>
              <span class="span-blue">没有账号？去<span class="span-login" @click="goRegister">注册</span></span>
            </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login, getMenus, getUserPersonal, getUserMenus } from "@/api/lib/api.js";
import md5 from "js-md5";
import { menuRecursion } from "@/common/utils/index";
export default {
  data () {
    return {
      logining: false,
      checked: false,
      yhsdp: null,
      loading: false,
      ruleForm: {
        userName: "",
        password: ""
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" }
        ]
      },
      popDoms: null
    };
  },
  methods: {
    handleSubmit (event) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.logining = true;
          login({
            username: this.ruleForm.userName,
            password: this.ruleForm.password,
            client_id: "valle",
            client_secret: "123456",
            grant_type: "password",
            scope: "server"
          })
            .then(res => {
              if (res.code === 0) {
                localStorage.setItem("username", this.ruleForm.userName);
                localStorage.setItem("token", res.data.access_token);
                this.$message.success("登录成功");
                this.getUserInfo(res.data.user_id);
                this.getAllMenu();
              } else {
                this.$message.error(res.message);
              }
              this.logining = false;
            })
            .catch(error => {
              this.$message.success(error.data);
              setTimeout(() => {
                this.logining = false;
              }, 1000);
            });
          if (this.checked) {
            // 保存用户名和密码
            localStorage.setItem(
              'userPwd',
              JSON.stringify({
                name: this.ruleForm.userName,
                pwd: this.ruleForm.password
              })
            )
          } else {
            localStorage.removeItem('userPwd')
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },

    /* 动态获取菜单 */
    getAllMenu () {
      let self = this;
      getUserMenus(0).then(res => {
        if (res.code === 0) {
          self.$store.dispatch("add_Menus", res.data);
          if (res.data && res.data.length > 0) {
            let menu = menuRecursion(res.data);
            self.$router.push(menu.path);
            self.$store.dispatch("now_Menus", menu); // 按钮权限所需数据
          } else {
            self.$message.warning(
              "该用户暂时没有任何权限,请先分配权限"
            );
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /* 动态获取菜单 */
    getUserInfo () {
      let self = this;
      getUserPersonal().then(res => {
        if (res.code === 0) {
          this.$store.commit('addUserInfo', res.data)
        }

      });
    },
    goRegister(){
      this.$router.push('/register');
    }
  },
  mounted () {
    // let user = localStorage.getItem('userPwd')
    // if (user) {
    //   this.ruleForm.userName = JSON.parse(user).name
    //   this.ruleForm.password = JSON.parse(user).pwd
    //   this.checked = true
    // }
    document.onkeydown = event => {
      var router = this.$route.path;
      var e = event || window.event || arguments.callee.caller.arguments[0];
      if (e && e.keyCode == 13 && this.$route.path == "/login") {
        // enter 键
        this.handleSubmit();
      }
    };
  },
  destroyed () {
  }
};
</script>

<style lang="scss" scoped>
.login-container {
  @include themify() {
    width: 100%;
    height: 100%;
    background-color: #3e3e58;
    position: relative;
    overflow: hidden;
    .content {
      .img-bg {
        position: fixed;
        width: 100%;
        height: 101%;
      }
      .title-wrapper {
        position: absolute;
        top: 4%;
        left: 20%;
        img {
          width: 246px;
        }
      }
      .yun {
        position: absolute;
      }
      .yun1 {
        top: 20%;
        right: 13%;
        animation: yun1 3s infinite alternate;
      }
      .yun2 {
        top: 10%;
        right: 23%;
        animation: yun2 4.5s infinite alternate;
      }
      .yun3 {
        top: 18%;
        right: 30%;
        animation: yun3 5s infinite alternate;
      }
      .login {
        width: 24vw;
        height: 45vh;
        background: #ffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20px;
        /* box-shadow: 0 0 20px rgb(102, 116, 169); */
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        left: 52%;
        .login_title {
          font-size: 2.9vh;
          color: #333333;
          padding-top: 6vh;
          font-weight: 700;
          position: relative;
          text-align: center;
          .border-bottom {
            height: 8px;
          }

          .border-bottom:after {
            content: "";
            position: absolute;
            left: 9.5vw;
            top: auto;
            bottom: 0;
            right: auto;
            height: 3px;
            width: 4vw;
            background-color: #6674a9;
          }
        }
        .login-page {
          width: 100%;
          text-align: center;
          background: #ffffff00;
          padding: 8vh 2.3vw;
          margin: 0 auto;
          position: relative;
          z-index: 99;
        }
      }
    }
    label.el-checkbox.rememberme {
      margin: 0px 0px 15px;
      text-align: left;
    }
  }
}

.bottom {
  height: 45vh;
  width: 100vw;
  background: #ffffff;
  z-index: 999;
  position: absolute;
  bottom: 0;
  .content {
    height: 100%;
    width: 100%;
    position: relative;
  }
}

.pops {
  position: absolute;
  .content {
    height: 100%;
    width: 100%;
    position: relative;
    div[class*="pop"] {
      border-radius: 50%;
      background: rgba($color: #ffffff, $alpha: 0.1);
      position: absolute;
    }
    .pop1 {
      height: 60%;
      width: 60%;
      top: 0;
      left: 0;
    }
    .pop2 {
      height: 40%;
      width: 40%;
      right: 10%;
      top: 30%;
    }
    .pop3 {
      height: 40%;
      width: 40%;
      bottom: 10%;
      left: 20%;
    }
  }
}

.logo {
  text-align: center;
  margin-top: 6vh;
}

.span-blue{
  color:#409eff;
  display: inline-block;
  .span-login{
    text-decoration: underline;
    cursor: pointer;
  }
}

@keyframes yun1 {
  from {
    right: 13%;
  }
  to {
    right: 16%;
  }
}

@keyframes yun2 {
  from {
    right: 23%;
  }
  to {
    right: 26%;
  }
}

@keyframes yun3 {
  from {
    right: 30%;
  }
  to {
    right: 34%;
  }
}
@keyframes line {
  from {
    top: -200vh;
  }
  to {
    top: -100vh;
  }
}
</style>

